<template>
  <div>
    <div class="row dashboard__panel justify-content-center">
      <div
        class="d-flex dashboard__panel--header dashboard__panel--header--title align-items-center"
      >
        <div class="col-8 text-truncate">{{ clusterHeader }}</div>
        <div
          class="col-4 d-flex justify-content-end align-items-center"
          v-if="clusterPercentile == ''"
        >
          {{ finalScore == null ? 'N/A' : `${finalScore}%` }}
        </div>
        <div class="col-4 d-flex justify-content-end align-items-center" v-else>
          {{ clusterPercentile }}
        </div>
      </div>
      <div class="row">
        <div
          v-if="data.length > 0 || score"
          class="col-12"
          id="container"
        ></div>
        <div
          v-else
          class="row align-items-center justify-content-center"
          style="height: 270px"
        >
          No data available for this year
        </div>
      </div>
      <div
        class="row justify-content-center tooltip rounded py-2"
        v-if="showTooltip && data.length > 1"
      >
        <div class="col">
          <div class="row">
            <div class="col-12 pb-2">
              <span :style="{ color: tooltipColor }"
                ><span class="dashboard__ticker-info-title">{{
                  clusterHeader
                }}</span>
                ({{ clusterQuantile }}/5)</span
              >
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 dashboard__radial-chart-info d-flex justify-content-start"
            >
              {{ clusterInfo }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!showTooltip && data.length > 1"
      >
        <div
          class="col-10 dashboard__radial-chart-info d-flex align-items-center px-0"
          style="height: 30px"
        >
          Hover over a cluster bar for its descriptors, or see below for
          specific stock details.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.report-text-overlay {
  position: absolute;
  top: 48.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: black;
  letter-spacing: -0px;
}
</style>
<script>
import Cluster from '../../assets/js/Cluster';

export default {
  props: ['score', 'chartValue'],
  watch: {
    score: {
      handler: function () {
        this.loadChart();
      },
      deep: true
    },
    chartValue: {
      handler: function () {
        let showInfo = false;
        for (let i = 0; i < this.chartValue.length; i++) {
          if (this.chartValue[i] != null) {
            showInfo = true;
          }
        }
        if (!showInfo) {
          this.clusterInfo = '';
        }
        this.loadChart();
      },
      deep: true
    }
  },
  data() {
    return {
      clusterPercentile: '',
      clusterHeader: 'Accounting Manipulation Risk Score',
      clusterInfo: '',
      clusterQuantile: 0,
      tooltipColor: 'black',
      finalScore: null,
      svg: null,
      svgNS: null,
      radial: [],
      data: [],
      xCenter: 0,
      yCenter: 120,
      circleRadius: 30,
      linePadding: 0,
      length: 15,
      angle: 18,
      stroke: 6,
      chartHeight: 240,
      chartWidth: '100%',
      showTooltip: false,
      loaded: false
    };
  },
  computed: {
    cluster() {
      return [
        [1, 0, '#4474A1'], //Accruals Management
        [Math.sin(90), -Math.cos(90), '#BBB0AA'], //Working Capital Signals
        [
          -Math.sin(90 - this.angle * 0.5),
          Math.cos(90 - this.angle * 0.5),
          '#7A6E6D'
        ], //Valuation Signals
        [Math.sin(90 - this.angle), -Math.cos(90 - this.angle), '#FC9798'], //Smoothing Activity
        [-Math.sin(90 - this.angle), -Math.cos(90 - this.angle), '#F24A55'], //Margin Signals
        [
          Math.sin(90 - this.angle * 0.5),
          Math.cos(90 - this.angle * 0.5),
          '#74BDB6'
        ], //Investing Activity
        [-Math.sin(90), -Math.cos(90), '#1C9898'], //Income Quality
        [-1, 0, '#F8CD4B'], //Growth Signals
        [-Math.sin(90), Math.cos(90), '#BB9906'], //Gearing
        [
          Math.sin(90 - this.angle * 0.5),
          -Math.cos(90 - this.angle * 0.5),
          '#68B063'
        ], //Credit
        [-Math.sin(90 - this.angle), Math.cos(90 - this.angle), '#39A243'], //Corporate Governance
        [Math.sin(90 - this.angle), Math.cos(90 - this.angle), '#FDBB70'], //Cash Quality
        [
          -Math.sin(90 - this.angle * 0.5),
          -Math.cos(90 - this.angle * 0.5),
          '#F88A08'
        ], //Business Manipulation
        [Math.sin(90), Math.cos(90), '#97CAE8'] //Asset Quality
      ];
    }
  },
  methods: {
    loadChart() {
      this.showChart();
      this.$forceUpdate();
    },
    showChart() {
      this.radial = [];
      this.data = [...this.chartValue];
      if (this.data.every((value) => !value)) this.data = [];
      this.finalScore =
        this.score == null ? null : Math.round(this.score * 100);

      const el = document.getElementById('container');
      let txtColor = 'white';

      if (el != null) {
        if (el.firstChild != null) {
          while (el.firstChild) {
            el.removeChild(el.firstChild);
          }
        }

        this.svg = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg'
        );
        this.svg.setAttribute('height', this.chartHeight);
        this.svg.setAttribute('width', this.chartWidth);
        this.svg.setAttribute('background-color', 'black');
        this.svg.setAttribute('style', 'text-align: center');
        this.svg.setAttribute('viewBox', '0 0 1 240');
        this.svgNS = this.svg.namespaceURI;

        const background = document.createElementNS(this.svgNS, 'rect');
        background.setAttribute('width', '100%');
        background.setAttribute('height', '100%');
        background.setAttribute('fill', 'transparent');
        this.svg.appendChild(background);

        let source = this.circleRadius + this.linePadding;
        let dest = 0;
        let angleX = 0;
        let angleY = 0;

        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i] != null) {
            if (this.data[i].cluster_quantile == null) {
              this.data[i].cluster_quantile = 0;
            }

            this.radial.push(
              new Cluster(
                this.data[i].cluster_quantile * 100,
                this.data[i].cluster_name,
                this.data[i].cluster_comment_text,
                this.data[i].cluster_quantile,
                this.svg,
                this.svgNS,
                this.xCenter,
                this.yCenter,
                this.cluster[i][2],
                this.stroke,
                this.data[i].cluster_value
              )
            );

            dest = this.length * this.data[i].cluster_quantile;
            angleX = this.cluster[i][0];
            angleY = this.cluster[i][1];
            this.radial[i].drawLine(
              'line' + i,
              source * angleX,
              source * angleY,
              dest * angleX,
              dest * angleY
            );
          }
        }

        let finalScore = new Cluster(
          null,
          null,
          null,
          null,
          this.svg,
          this.svgNS,
          0,
          0,
          null,
          null,
          null
        );
        this.$nextTick(() => {
          finalScore.drawText(
            'finalScore',
            this.finalScore != null ? this.finalScore + '%' : 'N/A',
            '50%',
            '50%',
            '20px',
            txtColor,
            'middle',
            'middle'
          );
        });

        let objTo = document.getElementById('container');
        objTo.appendChild(this.svg);

        for (let x = 0; x < 14; x++) {
          if (this.data[x] != null) {
            let element = document.getElementById('line' + x);
            if (element != null) {
              element.addEventListener('mouseover', () => {
                // self.setAttribute("stroke-width", "15");
                this.showTooltip = true;
                this.clusterHeader = this.radial[x].getLegend();
                this.clusterPercentile =
                  this.radial[x].getPercentile() == 0
                    ? 'N/A'
                    : this.radial[x].getPercentile() + '/5';
                this.clusterInfo = this.radial[x].getDescription();
                this.clusterQuantile = this.radial[x].getPercentile();
                this.tooltipColor = this.cluster[x][2];
              });
              element.addEventListener('mouseout', () => {
                // self.setAttribute("stroke-width", "10");
                this.showTooltip = false;
                this.clusterInfo = '';
                this.clusterHeader = 'Accounting Manipulation Risk Score';
                this.clusterPercentile = '';
                this.clusterQuantile = 0;
                this.tooltipColor = 'black';
              });
            }
          }
        }
      }
    }
  }
};
</script>
