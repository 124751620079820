<template>
  <div class="add-company-page">
    <div class="form-container">
      <div class="form-title">Sign Up</div>

      <form @submit="save">
        <div class="field">
          <label for="organizationName" class="label">Organization Name</label>
          <k-inputs
            v-model="entity.organizationName"
            name="organizationName"
            id="organizationName"
            :required="true"
            placeholder="Eg. TransparentlyPteLtd"
          />
        </div>

        <div class="field">
          <label for="contactName" class="label">Contact Name</label>
          <k-inputs
            v-model="entity.contactName"
            name="contactName"
            id="contactName"
            :required="true"
            placeholder="Eg. John Doe"
          />
        </div>

        <div class="field">
          <label for="organizationUrl" class="label">Organization URL</label>
          <k-inputs
            v-model="entity.organizationUrl"
            name="organizationUrl"
            id="organizationUrl"
            :required="true"
            type="url"
            placeholder="Eg. https://transparently.ai"
          />
        </div>

        <div class="field">
          <label for="contactEmail" class="label">Contact Email</label>
          <k-inputs
            v-model="entity.contactEmail"
            name="contactEmail"
            id="contactEmail"
            :required="true"
            type="email"
            placeholder="Eg. john-doe@mail.com"
          />
        </div>

        <div class="field">
          <k-button class="submit-btn" type="submit" theme-color="primary">
            Submit
          </k-button>
        </div>
      </form>
    </div>

    <k-dialog
      v-if="notificationDialog.show"
      :title="' '"
      @close="notificationDialog.show = false"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold" v-html="notificationDialog.title" />
          <p v-html="notificationDialog.message" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button class="me-2" fill-mode="outline" @click="continueToLogin">
            Continue to Login
          </k-button>
        </div>
      </div>
    </k-dialog>

    <div v-show="isLoading" class="loader">
      <div class="k-overlay"></div>
      <img src="@/assets/img/loader.svg" alt="" />
    </div>

    <k-dialog
      v-if="showAddCompanyDialog"
      :title="' '"
      @close="showAddCompanyDialog = false"
    >
      <div class="text-center"><icon-warning class="text-warning" /></div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold text-center mt-3">
            Organization name should not contains any malicious content
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="showAddCompanyDialog = false"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import DOMPurify from 'isomorphic-dompurify';
import SLBMixin from '@/shared/mixins/slb';
import ClearFastSpringUserInteractionMixin from '@/shared/mixins/clearFastSpringUserInteraction';
import {
  BLOG_RECEIVE,
  DEFAULT_BLOG_RECEIVE,
  MARKETABLE_CONTACT_STATUS,
  DEFAULT_MARKETABLE_CONTACT_STATUS
} from '@/components/tenantAdmin/constants';

export default {
  mixins: [SLBMixin, ClearFastSpringUserInteractionMixin],
  inject: {
    kendoForm: { default: {} }
  },
  data() {
    return {
      entity: {
        map: {},
        blogNewsArticlesSubscription: DEFAULT_BLOG_RECEIVE,
        hsMarketableStatus: DEFAULT_MARKETABLE_CONTACT_STATUS,
        selectAllRegions: false
      },
      notificationDialog: {
        show: false,
        title: null,
        message: null
      },
      isLoading: false,
      showAddCompanyDialog: false
    };
  },
  computed: {
    ...mapGetters(['principal']),
    ...mapGetters('fastspring', ['selectedBundle', 'bundles', 'subscription'])
  },
  created() {
    this.BLOG_RECEIVE = BLOG_RECEIVE;
    this.MARKETABLE_CONTACT_STATUS = MARKETABLE_CONTACT_STATUS;
  },
  async mounted() {
    //User logged in and don't have subscription => Logout so user can create new account
    if (this.principal && this.principal.idToken && !this.subscription) {
      this.clearFastSpringUserInteraction();
      this.$store.dispatch('logout');
    }
    this.addSBL();
    document.body.style.overflow = 'auto';
  },
  watch: {
    bundles(newValue) {
      if (!this.$route.query.bundle) return;

      this.SET_SELECTED_BUNDLE(
        newValue.find(({ pid }) => pid === this.$route.query.bundle)
      );
    }
  },
  methods: {
    ...mapMutations('fastspring', ['SET_SELECTED_BUNDLE']),
    save(event) {
      event.preventDefault();
      this.checkOrganizationName();
    },
    checkOrganizationName() {
      const cleanOrganizationName = DOMPurify.sanitize(
        this.entity.organizationName
      );

      if (this.entity.organizationName === cleanOrganizationName) {
        this.addCompany();
      } else {
        this.showAddCompanyDialog = true;
      }
    },
    addCompany() {
      this.entity.productId = this.selectedBundle.pid;

      this.isLoading = true;

      axios
        .post('/tenant/addWithAdmin', this.entity)
        .then((response) => {
          if (response.status === 200) {
            this.notificationDialog.message = `<p>We have sent you an email with instructions on how to setup your password. Kindly click the link provided in the email to proceed.
            <span style="color: red;">Please keep in mind that that link will expire in 3 days.</span>  <br/><br/> If you haven't received our email, please check your Spam or Junk folder and mark us as 'Not Spam' to ensure future communications reach your inbox.</p>`;
            this.notificationDialog.show = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    continueToLogin() {
      this.notificationDialog.show = false;
      this.clearFastSpringUserInteraction();
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss" scoped>
.add-company-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.form-container {
  width: 100%;
  max-width: 485px;
  background: #13283d;
  border-radius: 8px;
  padding: 24px 40px;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.form-title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
}

.label {
  margin-bottom: 10px;
}

::v-deep .k-input,
.k-dropdownlist {
  height: 44px;
  margin-bottom: 24px;
  background: #13151a;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  display: flex;
  align-items: center;

  .k-button {
    background: transparent;
  }
}

.dropdown-item {
  margin-bottom: 24px;
  padding: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  label {
    margin-left: 8px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
}

.submit-btn {
  height: 55px;
  width: 100%;
  margin-top: 12px;
  background: #ea8f36;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}
</style>
