<template>
  <div>
    <div>
      <div class="row dashboard__panel dashboard--medium">
        <div class="d-flex justify-content-between mt-2">
          <div>
            Exchange Country:
            <span
              class="dashboard--color1 pe-2"
              v-if="!!ticker.exchange_country"
              >{{ ticker.exchange_country }}</span
            >
            <span class="dashboard--color1 pe-2" v-else>N/A</span>
          </div>

          <div class="dashboard--xsmall" v-if="isPopupHaveData">
            <span class="k-link" @click="showTickerInfoPopup"
              ><icon-info
            /></span>
          </div>
        </div>

        <div class="d-flex">
          <div>
            Sector:
            <span class="dashboard--color2" v-if="!!ticker.sector_name">{{
              ticker.sector_name
            }}</span>
            <span class="dashboard--color2" v-else>N/A</span>
          </div>

          <div class="ps-2">
            Industry Group:
            <span class="dashboard--color2" v-if="!!ticker.industryGroup">{{
              ticker.industryGroup
            }}</span>
            <span class="dashboard--color2" v-else>N/A</span>
          </div>
        </div>

        <div class="row dashboard--small">
          <div class="col-5">
            <div>
              <span class="dashboard--color1">Mkt Value: </span
              >{{ ticker.mv == null ? 'N/A' : `${ticker.mv}$m` }}
            </div>
            <div>
              <div>
                <span class="dashboard--color1">Inc: </span>
                <span v-if="ticker.incorpCountry">{{
                  ticker.incorpCountry
                }}</span>
                <span v-else>N/A</span>
              </div>
              <div>
                <span class="dashboard--color1">Dom: </span
                ><span v-if="ticker.domicileCountry">{{
                  ticker.domicileCountry
                }}</span>
                <span v-else>N/A</span>
              </div>
              <div>
                <span class="dashboard--color1">Exc: </span
                ><span v-if="ticker.exchangeCountry">{{
                  ticker.exchangeCountry
                }}</span>
                <span v-else>N/A</span>
              </div>
              <div>
                <span class="dashboard--color1">Aud: </span
                ><span v-if="ticker.auditor">{{ ticker.auditor }}</span>
                <span v-else>N/A</span>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div>
              <span class="dashboard--color1">Loss Total: </span>
              <template v-if="ticker.telPessimistic || ticker.telOptimistic">
                {{
                  ticker.telPessimistic == null ? 'N/A' : ticker.telPessimistic
                }}
                to
                {{
                  ticker.telOptimistic == null ? 'N/A' : ticker.telOptimistic
                }}
                $m
              </template>
              <template v-else>N/A</template>
            </div>
            <div>
              <span class="dashboard--color1">Equity: </span>
              {{ ticker.equityLoss == null ? 'N/A' : `${ticker.equityLoss}$m` }}
            </div>
            <div>
              <span class="dashboard--color1">Debt: </span>
              <template v-if="ticker.dlPessimistic || ticker.dlOptimistic">
                {{
                  ticker.dlPessimistic == null ? 'N/A' : ticker.dlPessimistic
                }}
                to
                {{ ticker.dlOptimistic == null ? 'N/A' : ticker.dlOptimistic }}
                $m
              </template>
              <template v-else>N/A</template>
            </div>
          </div>

          <div class="col-2">
            <div class="dashboard--color1">Rating</div>
            <div style="font-size: 20px; font-weight: 600">
              {{ ticker.rating == null ? 'N/A' : ticker.rating }}
            </div>
          </div>
        </div>

        <div class="mb-1">
          Return from peak (%):
          <template
            v-if="
              ticker.finalScore && (ticker.rfpAbsolute || ticker.rfpRelative)
            "
          >
            <span class="dashboard--color1">Abs</span>
            {{ ticker.rfpAbsolute == null ? 'N/A' : ticker.rfpAbsolute }} -
            <span class="dashboard--color1">Rel</span>
            {{ ticker.rfpRelative == null ? 'N/A' : ticker.rfpRelative }}
          </template>
          <template v-else>N/A</template>
        </div>
      </div>

      <div
        class="dashboard__popup dashboard__ticker-info-popup dashboard__panel--overflow"
        v-if="showTIP"
        v-click-outside="onClickOutside"
      >
        <div class="row pb-2">
          <div class="col-1 pt-2">
            <icon-info-popup></icon-info-popup>
          </div>
          <div class="col-6 header d-flex justify-content-start pt-1">
            Stock Summary
          </div>
          <div
            class="col d-flex justify-content-end"
            @click="hideTickerInfoPopup"
          >
            <icon-close2></icon-close2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Inc - </span>
            <span v-if="popupsMap[POPUP_ID.incorporation]">
              {{ popupsMap[POPUP_ID.incorporation] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Mkt Value - </span>
            <span v-if="popupsMap[POPUP_ID.marketCapitalization]">
              {{ popupsMap[POPUP_ID.marketCapitalization] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Dom - </span>
            <span v-if="popupsMap[POPUP_ID.domicile]">
              {{ popupsMap[POPUP_ID.domicile] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Exc - </span
            ><span v-if="popupsMap[POPUP_ID.exchange]">
              {{ popupsMap[POPUP_ID.exchange] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Aud - </span
            ><span v-if="popupsMap[POPUP_ID.auditor]">
              {{ popupsMap[POPUP_ID.auditor] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Abs - </span
            ><span v-if="popupsMap[POPUP_ID.returnAbsolute]">
              {{ popupsMap[POPUP_ID.returnAbsolute] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Rel - </span
            ><span v-if="popupsMap[POPUP_ID.returnRelative]">
              {{ popupsMap[POPUP_ID.returnRelative] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Loss Total - </span
            ><span v-if="popupsMap[POPUP_ID.lossTotal]">
              {{ popupsMap[POPUP_ID.lossTotal] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Equity - </span
            ><span v-if="popupsMap[POPUP_ID.lossEquity]">
              {{ popupsMap[POPUP_ID.lossEquity] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Debt - </span
            ><span v-if="popupsMap[POPUP_ID.lossDebt]">
              {{ popupsMap[POPUP_ID.lossDebt] }}
            </span>
            <span>N/A</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { POPUP_ID } from '@/shared';

export default {
  props: ['ticker', 'popupsMap'],
  data() {
    return {
      showTIP: false
    };
  },
  computed: {
    POPUP_ID() {
      return POPUP_ID;
    },
    isPopupHaveData() {
      return (
        Object.keys(this.popupsMap) && Object.keys(this.popupsMap).length > 0
      );
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside() {
      this.hideTickerInfoPopup();
    },
    showTickerInfoPopup() {
      if (!this.showTIP) {
        this.showTIP = true;
      }
    },
    hideTickerInfoPopup() {
      if (this.showTIP) {
        this.showTIP = false;
      }
    }
  }
};
</script>
