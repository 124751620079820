<template>
  <div>
    <div class="row header">
      <div class="col-4 d-flex align-items-center">
        <k-button
          class="unset-min-width k-button-sm k-rounded-md k-icon-button me-2"
          theme-color="secondary"
          fill-mode="outline"
          @click="$router.go(-1)"
        >
          <span class="k-icon k-i-arrow-chevron-left"></span>
        </k-button>
        <span v-if="personList.organizationName">{{
          personList.organizationName
        }}</span>
      </div>
      <div class="col-8 d-flex justify-content-end align-items-center">
        <k-button
          class="ms-2"
          theme-color="primary"
          fill-mode="solid"
          @click="showDialog"
        >
          Add User
        </k-button>
      </div>
    </div>
    <k-grid
      :data-items="dataResult"
      :pageable="true"
      :sortable="true"
      :sort="sort"
      :skip="skip"
      :take="take"
      :columns="columns"
      @datastatechange="dataStateChange"
      @rowclick="rowClick"
      scrollable="none"
    >
      <template v-slot:lucaAccessible="{ props }">
        <td>
          <p class="mb-0">
            {{ props.dataItem.lucaAccessible ? 'Yes' : 'No' }}
          </p>
        </td>
      </template>
      <template v-slot:actionTemplate="{ props }">
        <td class="d-flex align-items-center">
          <actions-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1"
              @click="showEditDialog(props.dataItem, props.dataIndex)"
              ><icon-edit
            /></k-button>
            <k-button
              theme-color="secondary"
              fill-mode="solid"
              class="mx-2 my-1"
              @click="
                showDeleteConfirmationDialog(props.dataItem, props.dataIndex)
              "
              ><icon-trash
            /></k-button>
          </actions-button>
        </td>
      </template>
    </k-grid>

    <k-dialog v-if="dialog.show" :title="' '" @close="closeDialog">
      <div class="row mb-3">
        <div class="col-12 text-center" v-html="dialog.title"></div>
      </div>
      <form @submit.prevent="save">
        <div class="row">
          <div class="col-12">
            User Name
            <k-inputs
              v-model="entity.displayName"
              name="displayName"
              :required="true"
              placeholder="Eg. John Doe"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            Email
            <k-inputs
              v-model="entity.email"
              name="email"
              :required="true"
              placeholder="Eg. john.doe@mail.com"
              :pattern="'^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+([a-zA-Z]){2,7}$'"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            Access to Luca
            <div>
              <k-dropdown-native
                :data-items="accessOptions"
                :text-field="'text'"
                :data_item-key="'id'"
                :value="entity.lucaAccessible"
                @change="changeLucaAccessible"
              >
              </k-dropdown-native>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <k-button
              theme-color="secondary"
              fill-mode="outline"
              class="me-2"
              @click="closeDialog"
            >
              Cancel
            </k-button>
            <k-button type="submit" theme-color="primary"> Save </k-button>
          </div>
        </div>
      </form>
    </k-dialog>

    <confirm-popup
      :isShowing="deleteConfirmationDialog.show"
      @onClose="closeDeleteConfirmationDialog"
      @onConfirm="deleteUser()"
    >
      <p class="fw-bold text-center">
        Are you sure you want to delete
        {{ deleteConfirmationDialog.dataItem.displayName }}?
      </p>
    </confirm-popup>

    <confirm-popup
      :isShowing="notificationDialog.show"
      @onClose="notificationDialog.show = false"
      :hideConfirmButton="true"
    >
      <p class="fw-bold" v-html="notificationDialog.title"></p>
      <p v-html="notificationDialog.message"></p>
    </confirm-popup>
  </div>
</template>

<script>
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { userFormValidator } from '@/plugins/validator';
import ConfirmPopup from './components/confirmPopup';
import ActionsButton from './components/actionsButton.vue';
import { format } from 'date-fns';

export default {
  props: ['featureRequestId'],
  components: {
    ConfirmPopup,
    ActionsButton
  },
  data() {
    return {
      personList: null,
      entity: {
        displayName: '',
        disabled: false,
        email: ''
      },
      entityIndex: 0,
      skip: 0,
      take: 10,
      filter: null,
      columns: [
        { field: 'displayName', title: 'User Name' },
        { field: 'email', title: 'Contact Email' },
        {
          field: 'lucaAccessible',
          title: 'Luca Accessible',
          cell: 'lucaAccessible'
        },
        { field: '', title: 'Actions', cell: 'actionTemplate', width: 100 }
      ],
      accessOptions: [
        { id: true, text: 'Yes' },
        { id: false, text: 'No' }
      ],
      gridClickedRow: {},
      dialog: {
        show: false,
        title: null
      },
      deleteConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      notificationDialog: {
        show: false,
        title: null,
        message: null
      },
      userFormValidator
    };
  },
  mounted() {
    this.getRequestUserList();
  },
  computed: {
    listUser() {
      return this.personList && this.personList.personList
        ? this.personList.personList
        : [];
    },
    dataResult() {
      return process(this.listUser, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter
      });
    }
  },
  methods: {
    changeLucaAccessible(event) {
      this.entity.lucaAccessible = event.value;
    },
    getRequestUserList() {
      axios
        .get(`tenant/request-future-create/${this.featureRequestId}`)
        .then((response) => {
          this.personList = response.data || [];
        });
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
    },
    rowClick(event) {
      this.windowVisible = true;
      this.gridClickedRow = event.dataItem;
    },
    showDialog() {
      this.dialog.title = 'Add User to ' + this.tenantId;
      this.dialog.show = true;
    },
    showEditDialog(dataItem, index) {
      this.dialog.title = 'Edit User ' + dataItem.displayName;
      this.entity = this.$root.clone(dataItem);
      this.entityIndex = index;
      this.dialog.show = true;
      this.entity.lucaAccessible = this.accessOptions.find(
        ({ id }) => id == dataItem.lucaAccessible
      );
    },
    closeDialog() {
      this.dialog = {
        show: false,
        title: null
      };
      this.entity = {
        map: {}
      };
    },
    async save() {
      let formData = this.$root.clone(this.personList);

      const { lucaAccessible, ...entity } = this.entity || {};

      if (this.entityIndex) {
        formData.personList = formData.personList.map((data, index) => {
          if (index === this.entityIndex - 1)
            return {
              ...data,
              ...entity,
              lucaAccessible: lucaAccessible.id
            };

          return data;
        });
        this.entityIndex = 0;
      } else {
        formData.personList = [
          ...(formData.personList || []),
          { ...entity, lucaAccessible: lucaAccessible.id }
        ];
      }
      const response = await axios.post(
        'tenant/request-future-create',
        formData
      );

      if (response.status == 200) {
        this.closeDialog();
        this.getRequestUserList();
      }
    },
    showDeleteConfirmationDialog(dataItem, index) {
      this.deleteConfirmationDialog.dataItem = dataItem;
      this.deleteConfirmationDialog.show = true;
      this.entityIndex = index;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },
    async deleteUser() {
      let formData = this.$root.clone(this.personList);
      formData.personList = formData.personList.splice(this.entityIndex, 1);
      this.entityIndex = 0;
      const response = await axios.post(
        'tenant/request-future-create',
        formData
      );

      if (response.status == 200) {
        this.closeDeleteConfirmationDialog();
        this.getRequestUserList();
      }
    },
    formatDate(date) {
      if (!date) return;
      return format(new Date(date), 'MMM dd, yyyy');
    },
    formatTime(date) {
      if (!date) return;
      return format(new Date(date), 'HH:MM:SS');
    }
  }
};
</script>
